export const WWDObjOne = {
  id: "whatwedo",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  headline: "What We Do",
  description:
    "With a great support from our qualified teamwork and advance working facility, we deliver the best services to the clients.",
  imgStart: false,
  img: require("../../images/WhatWeDo/1.webp"),
  alt: "Iconiq",
  dark: false,
  primary: true,
  darkText: false,
};
