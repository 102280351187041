import React, { Suspense, useState } from "react";
import styled from "styled-components";
import "./App.css";
import { Helmet } from "react-helmet-async";
import images from "../../../../images/Workshop/gallery/images1.webp";
import images1 from "../../../../images/Workshop/gallery/images2.webp";
import images2 from "../../../../images/Workshop/gallery/images3.webp";
import images3 from "../../../../images/Workshop/gallery/images4.webp";
import images4 from "../../../../images/Workshop/gallery/images5.webp";

const Navbar2 = React.lazy(() => import("../../../Navbar2"));
const Sidebar = React.lazy(() => import("../../../Navbar2/Sidebar"));

export const WSContainer = styled.div`
  background: #fff;
`;

const WSgallery = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>
          PT ICONIQ MULTI DIMENSI - ICONIQ Interior | Workshop Gallery
        </title>
        <meta
          name="description"
          content="Images of Workshop from PT ICONIQ MULTI DIMENSI - ICONIQ Interior"
        />
        <link
          rel="canonical"
          href="https://iconiq-interior.co.id/workshop-gallery"
        />
      </Helmet>
      <WSContainer className="ws-all-container">
        <Suspense fallback={<div>Loading...</div>}>
          <Sidebar isOpen={isOpen} toggle={toggle} />
        </Suspense>

        <Suspense fallback={<div>Loading...</div>}>
          <Navbar2 toggle={toggle} />
        </Suspense>
        <div className="ws-wrapper">
          <div className="ws-text">
            <h1>
              PT ICONIQ MULTI DIMENSI <br /> WORKSHOP GALLERY
            </h1>
          </div>

          <div class="ws-container">
            <div class="image_workshop1">
              <img
                src={images}
                alt="iconiq workshop"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/files/Workshop_Gallery/images1.webp";
                }}
              />
            </div>
            <div class="image_workshop2">
              <img
                src={images1}
                alt="iconiq workshop"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/files/Workshop_Gallery/images2.webp";
                }}
              />
            </div>
            <div class="image_workshop3">
              <img
                src={images2}
                alt="iconiq workshop"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/files/Workshop_Gallery/images3.webp";
                }}
              />
            </div>
            <div class="image_workshop4">
              <img
                src={images3}
                alt="iconiq workshop"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/files/Workshop_Gallery/images4.webp";
                }}
              />
            </div>
            <div class="image_workshop5">
              <img
                src={images4}
                alt="iconiq workshop"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/files/Workshop_Gallery/images5.webp";
                }}
              />
            </div>
          </div>
        </div>
      </WSContainer>
    </>
  );
};

export default WSgallery;
