import React, { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

const Navbar2 = React.lazy(() => import("../../Navbar2"));
const Sidebar = React.lazy(() => import("../../Navbar2/Sidebar"));
const Innocean = React.lazy(() => import("../Featured/All_Project/Innocean"));
const Neptune = React.lazy(() => import("../Featured/All_Project/Neptune"));
const Memi = React.lazy(() => import("../Featured/All_Project/Memi"));
const PEPC = React.lazy(() => import("../Featured/All_Project/PEPC"));
const Sarulla = React.lazy(() => import("../Featured/All_Project/Sarulla"));
const MPI = React.lazy(() => import("../Featured/All_Project/Mpi"));

export const DetailsContainers = styled.div`
  height: 100%;
  background: #0f0f0f;
  padding-top: 7rem;
  width: 100%;
  overflow: hidden;
`;

export const DetailsText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  padding-left: 2rem;
  @media (max-width: 575.98px) {
    padding: 10px 0;
  }
`;

export const Title = styled.h1`
  text-align: left;
  color: #e85508;
  font-size: 40px;
  letter-spacing: -0.03em;

  @media (max-width: 575.98px) {
    font-size: 24px;
    text-align: center;
  }
`;

export const Text2 = styled.p`
  color: #e2e2e2;
  padding-top: 3rem;
  text-align: left;
  max-width: 610px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 160%;

  @media (max-width: 575.98px) {
    max-width: 340px;
    text-align: center;
  }
`;

export const Container = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column ;
  justify content: center;
`;

const Featured = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>
          PT ICONIQ MULTI DIMENSI - ICONIQ Interior | Featured Project
        </title>
        <meta
          name="description"
          content="PT ICONIQ MULTI DIMENSI | Iconiq Interior Featured Projects"
        />
        <link
          rel="canonical"
          href="https://iconiq-interior.co.id/project/featured-project"
        />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <Sidebar isOpen={isOpen} toggle={toggle} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar2 toggle={toggle} />
      </Suspense>
      <DetailsContainers>
        <DetailsText>
          <Title>All Featured Projects</Title>
          <Text2>Home / Project / Featured-Project</Text2>
        </DetailsText>
        <Container>
          <Suspense fallback={<div>Loading...</div>}>
            <Innocean />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <Neptune />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <Memi />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <PEPC />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <Sarulla />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <MPI />
          </Suspense>
        </Container>
      </DetailsContainers>
    </>
  );
};

export default Featured;
