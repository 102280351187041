import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import Certification from "./components/Certification";
import WSGallery from "./components/Workshop/Pages/WSGallery";
import Featured from "./components/Project/Featured";
import Innocean from "./components/Project/Featured/Pages/Innocean";
import Neptune from "./components/Project/Featured/Pages/Neptune";
import Memi from "./components/Project/Featured/Pages/Memi";
import Pepc from "./components/Project/Featured/Pages/Pepc";
import Sarulla from "./components/Project/Featured/Pages/Sarulla";
import Mpi from "./components/Project/Featured/Pages/Mpi";

class App extends React.Component {
  // fake authentication Promise
  authenticate() {
    return new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds
  }

  componentDidMount() {
    this.authenticate().then(() => {
      const ele = document.getElementById("ipl-progress-indicator");
      if (ele) {
        // fade out
        ele.classList.add("available");
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = "";
        }, 2000);
      }
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/certification" component={Certification} />
          <Route
            exact
            path="/workshop/workshop-gallery"
            component={WSGallery}
          />
          <Route
            exact
            path="/project/featured-project/innocean"
            component={Innocean}
          />
          <Route
            exact
            path="/project/featured-project/neptune"
            component={Neptune}
          />
          <Route exact path="/project/featured-project/memi" component={Memi} />
          <Route exact path="/project/featured-project/pepc" component={Pepc} />
          <Route
            exact
            path="/project/featured-project/sarulla"
            component={Sarulla}
          />
          <Route exact path="/project/featured-project/mpi" component={Mpi} />
          <Route exact path="/project/featured-project" component={Featured} />
        </Switch>
      </Router>
    );
  }
}

export default App;
